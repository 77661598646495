import axios from 'axios';
import fileSaver from 'file-saver';
import type { DataQualityReport } from '~/types';

export function useDataService() {
    const config = useRuntimeConfig();
    const api = axios.create({
        baseURL: `${config.public.baseUrl}/api/data`,
        // baseURL: `http://localhost:3000`,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'max-age=3600',
        }
    })


    async function getData(url: string, params: any) {
        const { data } = await api.post('/data', {
            url,
            ...params
        }, { headers: { 'Cache-Control': 'max-age=3600', } });
        return data;
    }

    async function saveData(url: string, params: any, target: string) {
        const { data } = await api.post('/data', {
            url,
            ...params,
            upload: {
                link: target
            }
        }, { headers: { 'Cache-Control': 'max-age=3600', } });
        return data;
    }

    async function getColumns(id: string) {
        const { data } = await api.get<{ col_name: string, data_type: string }[]>('/structure', {
            params: { id }
        });

        return data;
    }

    async function exportData(url: string, type: string, filename = 'data') {
        const { data } = await api.get('/export', {
            params: { url, type },
            responseType: 'blob',
            // set timeout to 5 minutes
            timeout: 300000
        });

        const file = `${filename}.${type}`;
        const { saveAs } = fileSaver;
        return saveAs(data, file);
    }

    async function getUniqueValues(url: string, column: string, filterModel: any) {
        const { data } = await api.post('/unique', {
            url,
            column,
            filterModel
        });

        return data;
    }

    async function uploadFile(url: string, file: File, progress?: (percentage: number) => void) {
        await axios.put(url, file, {
            headers: {
                'x-ms-blob-type': 'BlockBlob',
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (progress) {
                        progress(percentage);
                    }
                }
            }
        })
    }

    async function calculateDataQuality(url: string,) {
        const { data } = await api.get<DataQualityReport | { error: string }>('/data-quality', {
            params: { url }
        });

        return data;
    }

    async function renameColumn(url: string, oldName: string, newName: string) {
        const { data } = await api.post('/rename-column', {
            url,
            oldName,
            newName
        });

        return data;
    }

    async function dropColumn(url: string, column: string) {
        const { data } = await api.post('/drop-column', {
            url,
            column
        });

        return data;
    }

    return {
        saveData,
        getData,
        getUniqueValues,
        exportData,
        uploadFile,
        calculateDataQuality,
        renameColumn,
        dropColumn,
        getColumns
    }
}